import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LoginPage } from '@/features/auth/presentation/pages/LoginPage.tsx';
import { ProtectedRoutes } from '@/features/auth/presentation/pages/ProtectedRoutes.tsx';
import { startSipConnection } from '@/features/calling/domain/usecases/startSipConnection.ts';
import { StartChatConnectionUsecase } from '@/features/chat/domain/usecases/StartChatConnectionUsecase.ts';
import { CallingPage } from '@/features/calling/presentation/pages/CallingPage.tsx';
import { ChatRoutes } from '@/features/chat/presentation/pages/ChatRoutes.tsx';
import { InboxLayout } from '@/features/chat/presentation/pages/InboxLayout.tsx';
import { ConnectToReportingUsecase } from '@/features/report/domain/usecases/ConnectToReportingUsecase.ts';
import { SetIdentityUsecase } from '@/features/report/domain/usecases/SetIdentityUsecase.ts';
import { SearchOverview } from '@/features/search/presentation/pages/SearchOverview.tsx';

const queryClient = new QueryClient();

// Here we define the routes for the application.
const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  // The ProtectedRoutes component protects all children elements from unauthenticated access.
  // If the user is not authenticated, it will redirect to the login page.
  {
    path: '/',
    element: <ProtectedRoutes />,
    children: [
      {
        // The ChatRoutes component is used to protect certain routes that require authentication
        // to the Matrix service. It checks if the chat is initialized and then renders the route.
        element: <ChatRoutes />,
        path: '/',
        children: [
          {
            path: '/:roomId?/:eventId?',
            element: <InboxLayout />,
          },
          {
            path: '/search',
            element: <SearchOverview />,
          },
        ],
      },
      {
        element: <CallingPage />,
        path: '/calling',
      },
    ],
  },
]);

export const App = () => {
  const connectToReporting = useUsecase(ConnectToReportingUsecase);
  const setIdentity = useUsecase(SetIdentityUsecase);
  const authStore = useUsecase(GetAuthStoreUsecase).call();
  const startChatConnection = useUsecase(StartChatConnectionUsecase);
  const { isAuthenticated, uuid } = authStore();

  // When developing the useEffects will be triggered twice because of React Strict mode.
  // This is no issue as each of these functions already check if they are already connected.
  useEffect(() => {
    if (isAuthenticated) {
      startChatConnection.call();
      startSipConnection();
      connectToReporting.call().then(() => {
        setIdentity.call(uuid);
      });
    } else {
      connectToReporting.call().then(() => {
        // TODO identify anonymously
        // https://voipgrid.atlassian.net/browse/CI-756
      });
    }
  }, [uuid, connectToReporting, isAuthenticated, setIdentity, startChatConnection]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
