import { type MatrixEvent, type Room } from 'matrix-js-sdk';

import { type MessageEntity } from '@/features/chat/domain/entities/MessageEntity.ts';
import { type RoomEntity } from '@/features/chat/domain/entities/RoomEntity.ts';

export const transformMatrixRoomMessageEventToMessageEntity = (
  event: MatrixEvent,
  matrixRoom: Room,
  room: RoomEntity,
  userId: string,
): MessageEntity => {
  const eventId = event.getId();

  if (!eventId) {
    throw new Error('Event ID is undefined');
  }

  const senderDisplayName = room?.members.find((member) => member.userId === event.getSender())?.rawDisplayName;
  const messageType = userId === event.getSender() ? 'outgoing' : 'incoming';

  return {
    eventId: eventId,
    message: event.getContent().body,
    timestamp: event.getTs(),
    senderId: event.getSender() || 'Unknown',
    senderDisplayName: senderDisplayName || 'Unknown',
    type: messageType,
    isRead: matrixRoom.hasUserReadEvent(userId, eventId) ?? false,
    roomId: matrixRoom.roomId,
  };
};
