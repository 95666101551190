import { VoipgridApi } from '@/common/data/api/VoipgridApi.ts';
import { container } from '@/container.ts';

class VoipAccountRepository {
  async getSelectedWebphoneVoipAccount() {
    return await container.get(VoipgridApi).getSelectedWebphoneVoipAccount();
  }
}

export const voipAccountRepository = new VoipAccountRepository();
