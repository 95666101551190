import { Container } from 'inversify';

import { VoipgridApi } from '@/common/data/api/VoipgridApi.ts';
import { PushNotificationUsecase } from '@/common/domain/usecases/PushNotificationUsecase.ts';
import { AuthRepository } from '@/features/auth/data/repositories/AuthRepository.ts';
import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';
import { GetAuthStoreUsecase } from '@/features/auth/domain/usecases/GetAuthStoreUsecase.ts';
import { LoginUsecase } from '@/features/auth/domain/usecases/LoginUsecase.ts';
import { LogoutUsecase } from '@/features/auth/domain/usecases/LogoutUsecase.ts';
import { ChatRepository } from '@/features/chat/data/repositories/ChatRepository.ts';
import { GetChatStoreUsecase } from '@/features/chat/domain/usecases/GetChatStoreUsecase.ts';
import { GetMessagesStoreUsecase } from '@/features/chat/domain/usecases/GetMessagesStoreUsecase.ts';
import { GetRoomMessageDraftsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomMessageDraftsStoreUsecase.ts';
import { GetRoomUsecase } from '@/features/chat/domain/usecases/GetRoomUsecase.ts';
import { GetRoomsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomsStoreUsecase.ts';
import { MarkMessageAsReadUsecase } from '@/features/chat/domain/usecases/MarkMessageAsReadUsecase.ts';
import { SendMessageUsecase } from '@/features/chat/domain/usecases/SendMessageUsecase.ts';
import { SetCurrentRoomIdUsecase } from '@/features/chat/domain/usecases/SetCurrentRoomIdUsecase.ts';
import { SetMessagesInStoreUsecase } from '@/features/chat/domain/usecases/SetMessagesInStoreUsecase.ts';
import { SetRoomMessageDraftUsecase } from '@/features/chat/domain/usecases/SetRoomMessageDraftUsecase.ts';
import { StartChatConnectionUsecase } from '@/features/chat/domain/usecases/StartChatConnectionUsecase.ts';
import { SegmentConnector } from '@/features/report/data/api/SegmentConnector.ts';
import { SentryConnector } from '@/features/report/data/api/SentryConnector.ts';
import { ReportRepository } from '@/features/report/data/repository/ReportRepository.ts';
import { ConnectToReportingUsecase } from '@/features/report/domain/usecases/ConnectToReportingUsecase.ts';
import { SetIdentityUsecase } from '@/features/report/domain/usecases/SetIdentityUsecase.ts';
import { TrackUserActionUsecase } from '@/features/report/domain/usecases/TrackUserActionUsecase.ts';
import { SearchSphereApi } from '@/features/search/data/api/holodeck/SearchSphereApi.ts';
import { SearchRepository } from '@/features/search/data/repositories/SearchRepository.ts';
import { GetChatSearchResultsUsecase } from '@/features/search/domain/usecases/GetChatSearchResultsUsecase.ts';
import { GetRoomSearchResultsUsecase } from '@/features/search/domain/usecases/GetRoomSearchResultsUsecase.ts';

// Create a new container instance which will be used to bind the usecases and repositories.
export const container = new Container({ defaultScope: 'Singleton' });

// common
container.bind(VoipgridApi).toSelf();
container.bind(SearchSphereApi).toSelf();
container.bind(PushNotificationUsecase).toSelf();

// features/auth
container.bind(AuthStore).toSelf();
container.bind(AuthRepository).toSelf();
container.bind(GetAuthStoreUsecase).toSelf();
container.bind(LogoutUsecase).toSelf();
container.bind(LoginUsecase).toSelf();

// features/report
container.bind(SentryConnector).toSelf();
container.bind(SegmentConnector).toSelf();
container.bind(ReportRepository).toSelf();
container.bind(ConnectToReportingUsecase).toSelf();
container.bind(SetIdentityUsecase).toSelf();
container.bind(TrackUserActionUsecase).toSelf();

// features/chat
container.bind(ChatRepository).toSelf();
container.bind(GetChatStoreUsecase).toSelf();
container.bind(GetRoomUsecase).toSelf();
container.bind(SetMessagesInStoreUsecase).toSelf();
container.bind(GetRoomMessageDraftsStoreUsecase).toSelf();
container.bind(GetRoomsStoreUsecase).toSelf();
container.bind(MarkMessageAsReadUsecase).toSelf();
container.bind(SendMessageUsecase).toSelf();
container.bind(SetCurrentRoomIdUsecase).toSelf();
container.bind(GetMessagesStoreUsecase).toSelf();
container.bind(SetRoomMessageDraftUsecase).toSelf();
container.bind(StartChatConnectionUsecase).toSelf();

// features/search
container.bind(SearchRepository).toSelf();
container.bind(GetChatSearchResultsUsecase).toSelf();
container.bind(GetRoomSearchResultsUsecase).toSelf();
