import { faCommentAltLines } from '@awesome.me/kit-368b0118d2/icons/classic/regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useUsecase } from '@/common/presentation/hooks/useUsecase.ts';
import { Page } from '@/common/presentation/pages/Page.tsx';
import { GetRoomsStoreUsecase } from '@/features/chat/domain/usecases/GetRoomsStoreUsecase.ts';
import { SetCurrentRoomIdUsecase } from '@/features/chat/domain/usecases/SetCurrentRoomIdUsecase.ts';
import { ChatHeader } from '@/features/chat/presentation/components/ChatHeader.tsx';
import { ChatView } from '@/features/chat/presentation/pages/ChatView.tsx';
import { RoomList } from '@/features/chat/presentation/pages/RoomList.tsx';
import { translate } from '@/features/translations/domain/usecases/translate.ts';

export const InboxLayout = () => {
  const { roomId, eventId } = useParams();
  const getRoomsStoreUsecase = useUsecase(GetRoomsStoreUsecase);
  const setCurrentRoomIdUsecase = useUsecase(SetCurrentRoomIdUsecase);
  const { isInitialized: isRoomsInitialized } = getRoomsStoreUsecase.call();

  useEffect(() => {
    if (roomId) {
      setCurrentRoomIdUsecase.call(roomId);
    }
  }, [roomId, setCurrentRoomIdUsecase]);

  return (
    <Page title={translate('messages')}>
      <main className="inbox-small md:inbox-large">
        <RoomList />
        {isRoomsInitialized && (
          <div className="chat bg-white px-4">
            {roomId ? (
              <div className="flex h-full flex-col">
                <ChatHeader roomId={roomId} />
                <ChatView roomId={roomId} urlEventId={eventId} />
              </div>
            ) : (
              <div className="flex h-full flex-col items-center justify-center">
                <div className="mb-4">
                  <FontAwesomeIcon icon={faCommentAltLines} className="size-12 text-gray-400" />
                </div>
                <span className="text-lg font-medium text-gray-700">{translate('select_chat_to_read')}</span>
              </div>
            )}
          </div>
        )}
      </main>
    </Page>
  );
};
