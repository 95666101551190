import { EventTimeline, EventType, type Room } from 'matrix-js-sdk';

import { type RoomEntity } from '@/features/chat/domain/entities/RoomEntity.ts';
import { type RoomMemberEntity } from '@/features/chat/domain/entities/RoomMemberEntity.ts';

/**
 * Transforms the given room to a RoomEntity.
 */
export const transformMatrixRoomToRoomEntity = (room: Room): RoomEntity => {
  const members: Array<RoomMemberEntity> = room.getMembers().map((member) => {
    return {
      userId: member.userId,
      rawDisplayName: member.rawDisplayName,
    };
  });

  const state = room.getLiveTimeline().getState(EventTimeline.BACKWARDS);
  // Since there can only be one room create event, we'll just take the first one.
  const roomCreateEvent = state?.getStateEvents(EventType.RoomCreate, '');
  const createTimeStamp = roomCreateEvent ? roomCreateEvent.getTs() : 0;

  const events = room.getLiveTimeline().getEvents();
  const lastMessageEvent = events.filter((event) => event.getType() === EventType.RoomMessage).pop();

  return {
    name: room.name,
    roomId: room.roomId,
    unreadNotificationCount: room.getUnreadNotificationCount(),
    lastMessage: lastMessageEvent ? lastMessageEvent.getContent().body : '',
    lastMessageTimestamp: lastMessageEvent ? lastMessageEvent.getTs() : Date.now(),
    members: members,
    createdAt: createTimeStamp,
  };
};
