import { injectable } from 'inversify';

import { HolodeckApi } from '@/common/data/api/abc/HolodeckApi.ts';
import { chatStore } from '@/features/chat/data/stores/chatStore.ts';
import { type ChatSearchResultsModel } from '@/features/search/data/models/ChatSearchResultsModel.ts';
import { type RoomSearchResultsModel } from '@/features/search/data/models/RoomSearchResultsModel.ts';

@injectable()
export class SearchSphereApi extends HolodeckApi {
  domain = '/communication-interfaces';
  service = '/search-sphere';

  async getChatSearchResults(searchQuery: string) {
    return this.request<ChatSearchResultsModel>({
      url: `/search/chat?term=${searchQuery}`,
      method: 'GET',
      customHeaders: {
        'Matrix-Token': chatStore.getState().accessToken,
      },
    });
  }

  async getRoomSearchResults(searchQuery: string) {
    return this.request<RoomSearchResultsModel>({
      url: `/search/room?term=${searchQuery}`,
      method: 'GET',
      customHeaders: {
        'Matrix-Token': chatStore.getState().accessToken,
      },
    });
  }
}
