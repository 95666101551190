import axios, { AxiosHeaders } from 'axios';
import { inject, injectable } from 'inversify';

import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';

export type HolodeckApiRequestOptions = {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: object;
  customHeaders?: Record<string, string>;
};

@injectable()
export abstract class HolodeckApi {
  domain = '';
  service = '';

  constructor(@inject(AuthStore) protected authStore: AuthStore) {}

  protected async request<T>({ url, method, data, customHeaders }: HolodeckApiRequestOptions) {
    const headers = new AxiosHeaders(customHeaders);
    const { token } = this.authStore.getState();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    // Below is a workaround to ignore the domain reference when running locally
    // since services run locally don't have a domain defined in their URL.
    if (import.meta.env.VITE_HOLODECK_URL.startsWith('http://localhost')) {
      this.domain = '';
    }

    return await axios.request<T>({
      url: `/holodeck${this.domain}${this.service}${url}`,
      method,
      data,
      headers,
    });
  }
}
