import { useFlag } from '@unleash/proxy-client-react';

export const CallingPage = () => {
  const isActive = useFlag('conversations_calling-is-active');
  const isLocalDev = import.meta.env.MODE === 'development';

  if (isLocalDev || isActive) {
    return <div>I am calling</div>;
  }

  return <div>Forbidden</div>;
};
