import { isAxiosError } from 'axios';
import { inject, injectable } from 'inversify';

import { VoipgridApi } from '@/common/data/api/VoipgridApi.ts';
import { type ApiTokenErrorModel } from '@/features/auth/data/models/ApiTokenErrorModel.ts';
import { AuthStore } from '@/features/auth/data/stores/AuthStore.ts';
import { AuthErrorCodes } from '@/features/auth/data/types/AuthErrorCodes.ts';

/**
 * The AuthRepository class handles all authentication operations.
 * It mainly works by retrieving a token (after login) and then storing it in the auth store.
 * This token will then be used for future API requests.
 * Logging out simply resets the auth store back to its initial state (unauthenticated).
 */
@injectable()
export class AuthRepository {
  constructor(
    @inject(VoipgridApi) private voipgridApi: VoipgridApi,
    @inject(AuthStore) private authStore: AuthStore,
  ) {}

  /**
   * Login in with the username, password and (if 2FA enabled) the two-factor code.
   * Updates the auth store which means the frontend can react (pun intended) to the new state.
   */
  async login(email: string, password: string, twoFactorToken: string) {
    return await this.voipgridApi
      .getApiToken(email, password, twoFactorToken)
      .then(async ({ data }) => {
        this.authStore.setState({ email, token: data.api_token });

        // Fetch additional user details after setting the token in the store because
        // this VoIPGRID API request gets the token from the store.
        // When all of this is done then we deem the user authenticated.
        return await this.voipgridApi.getUserDetails();
      })
      .then(({ data }) => {
        this.authStore.setState({
          uuid: data.uuid,
          firstName: data.first_name,
          lastName: data.last_name,
          clientName: data.client.name,
          isAuthenticated: true,
        });
      })
      .catch((error) => {
        if (isAxiosError<ApiTokenErrorModel>(error) && error.response) {
          const { email, password, two_factor_token: twoFactorToken } = error.response.data.apitoken;

          if (twoFactorToken?.includes('this field is required')) {
            throw Error(AuthErrorCodes.twoFactorAuthenticationRequired);
          }
          if (email?.includes('this field is required')) {
            throw Error(AuthErrorCodes.emailRequired);
          }
          if (password?.includes('this field is required')) {
            throw Error(AuthErrorCodes.passwordRequired);
          }
          if (
            email?.includes('invalid email') ||
            password?.includes('invalid password') ||
            twoFactorToken?.includes('invalid two_factor_token')
          ) {
            throw Error(AuthErrorCodes.invalidCredentials);
          }
        }

        throw error;
      });
  }

  getAuthStore() {
    return this.authStore.store;
  }

  logout() {
    this.authStore.reset();
  }
}
